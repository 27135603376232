import React from 'react';
import { useNavigate } from 'react-router-dom';
import FAQComponent from './FAQComponent';
import ContactUsComponent from './ContactUsComponent';
import { useTranslation } from 'react-i18next';

export default function HelpComponent() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <>
      <div className=" mt-5">
        <h1 className="text-center mb-4" style={{ color: '#e95d80' }}>{t('faq')}</h1>
        <FAQComponent />
        <ContactUsComponent />
      </div>
    </>
  );
}
