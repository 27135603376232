import '../../css/legal.css'

export default function Cookies() {
    return (
        <div className='container'>
            <hr></hr>
            <div className='starting_section'>
                <h1 className='heading'>
                    Cookie Policy
                </h1>
                <h3 className='sub_heading'>
                    Last Modified: 7th March 2024
                </h3>

            </div>
            <hr></hr>
            <p>
            This document explains the types of cookies we use on our web-based services and products, aiming to provide transparency and protect your privacy. For further details about your privacy, please also review our Privacy Policy. We value your online experience and are committed to making it both secure and enjoyable.
                <br></br><br></br><br></br>
                If you have any questions regarding our privacy practices, please check the end of this Privacy Policy for contact details. We encourage you to read this Cookie Policy alongside our Privacy Policy and Terms of Service.
            </p>
            <div className='privacy terms cookies'>
                <h1>What is a Cookie?</h1>
                <p>
                    Cookie of course is the unobtrusive digital instrument that awaits upon your computer while you browse the wide universe of the web and cookie is the one thing that in a certain way assists in amplifying the online experience. Imagine them to be your faithful virtual memory, promptly remembering your preferences, login details, and even products stored in your virtual shopping cart all with the aim of rendering your web interactions smooth and efficient. However, their importance goes beyond this function. Cookies do not lurk in the corner; they are messengers, transmitting your online activities to the owners of websites. This critical data provides insights into how you like to do things, which helps webmasters to redesign their sites according to your specific needs, making virtual travel more comfortable and fun. Essentially, cookies are the underappreciated saviors of the internet world, working tirelessly to improve your digital escapades.
                </p>
                <h1>
                    What Cookies We Use
                </h1>
                <p>As is the common practice with many websites, MJMarry uses cookies to collect additional information on the use of our website. These cookies are valuable tools that therefore allow us to customize our services to enhance your user satisfaction. Although cookies are essential in many ways, it is important to note that they are not needed for all parts of our services. However, we use session cookies and persistent cookies thoughtfully. These cookies give us vital information about how you use our services which enables us to monitor and evaluate the overall use of the services by our users. This information helps us efficiently guide web traffic routing in our services and tailor and perfect our offerings to your needs and tastes. It is our unwavering dedication to consistently improve your MJMarry’s web services experience.
                </p>
                <h1>
                    More Information Regarding Cookies
                </h1>
                <p>
                    Most of the internet browsers accept cookies automatically. Nevertheless, you can alter your browser settings to either reject cookies or be notified when accepting such cookies from the sites that you visit. It should be pointed out that this level of flexibility gives you control over your online privacy settings but if cookies are disabled, certain features will not work as well or stop working altogether. We therefore advise you to carefully consider your cookie settings so that privacy is maintained while optimum functionality allows the best use of our services. The features of your browser make you the master of what happens online.
                </p>
                <p>For more information on cookies, including details of how to see which cookies have been set and ways to manage or delete them please visit All About Cookies. Also, make use of your browser’s settings for cookie management.
                </p>
            </div>
        </div>
    )
};