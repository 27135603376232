import React from "react";
import { useTranslation } from "react-i18next";

export const ReviewsSection = () => {

    const { t, i18n } = useTranslation();

    return (
        <section className="container mx-auto text-center py-5" style={{ backgroundColor: '#e95d80' }}>
            <h2 className="text-white mb-4" style={{ fontSize: '40px', fontWeight: 'bold' }}>{t('reviewt')}</h2>

            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center" style={{ gap: '20px' }}>
                <div className="review-card"
                    style={{ maxWidth: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                    <p style={{ fontStyle: 'italic' }}>"{t('r1')}"</p>
                    <strong>- {t('r1p')}</strong>
                </div>

                <div className="review-card"
                    style={{ maxWidth: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                    <p style={{ fontStyle: 'italic' }}>"{t('r2')}"</p>
                    <strong>- {t('r2p')}</strong>
                </div>

                <div className="review-card"
                    style={{ maxWidth: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                    <p style={{ fontStyle: 'italic' }}>"{t('r3')}"</p>
                    <strong>- {t('r3p')}</strong>
                </div>
            </div>
        </section>
    )
};