import React, { useEffect, useState } from 'react';
import { useAuth } from "../../context/AuthContext";
import AdminTrends from "./AdminTrends";
import DisableAccountComponent from "./DisableAccComponent";

const Dashboard = () => {
    const { currentUser, getCurrentUser } = useAuth();
    useEffect(() => {
        getCurrentUser().then(usr => {
            console.log(usr)
        }).then(err => {
            console.log(err)
        })
    }, [])

    if (!currentUser) {
        return <>
            Loading
        </>
    }

    return (
        <>
            {
                currentUser.role === 'Manager' ?
                    <AdminTrends /> :
                    <DisableAccountComponent />
            }
        </>
    );
};

export default Dashboard;
