import { useState } from "react";
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,

  Box,
  IconButton,
  Menu, MenuItem
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchAppBar from "../../../ui/SearchInput";

import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from "react-router";

const drawerWidth = 300;

export const AdminNav = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const {currentUser, logout } = useAuth();
  const navigate = useNavigate()

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };



  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutAccount = () => {
    logout();
  };

  const adminListItems = [
    { name: "Dashboard", link: "/dashboard/trends", icon: <DashboardRoundedIcon /> },
    { name: "Verify Accounts", link: "/dashboard/verify-accounts", icon: <ManageAccountsRoundedIcon /> },
    { name: "All Users", link: "/dashboard/all-users", icon: <PeopleAltRoundedIcon /> }
  ];

  const userListItems = [
    { name: "Account Management", link: "/dashboard/user/disableacc", icon: <DashboardRoundedIcon /> },
  ];

  const list = currentUser?.role=="Manager"?adminListItems:userListItems;

  const drawer = (
    <div>
      <Box sx={{
        textAlign: "center",
        marginTop: 2
      }}>
        <h1>MjMarry</h1>
      </Box>
      <Toolbar />
      <List>
        {list.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              component="a"
              href={item.link}
              sx={{

                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#', // Change color to white on hover
                  '& .MuiListItemIcon-root': {
                    color: '#E95D80',
                  },
                  '& .MuiListItemText-primary': {
                    color: '#E95D80',
                  },
                },
              }}
            >
              <ListItemIcon sx={{ color: '#fff', }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} sx={{ color: 'inherit' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <SearchAppBar />
          </div>

          {auth && (
            <div>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{ ml: 'auto' }}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logoutAccount}>Logout</MenuItem>
                <MenuItem onClick={() => {
                    navigate("dashboard/profile")
                }}>Edit Profile</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#E95D80",
              color: "white",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#E95D80",
              color: "white",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
