import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper, TextField, Button, CircularProgress, Alert, Modal, Box } from "@mui/material";
import { useAuth } from '../../context/AuthContext';

const drawerWidth = 300;

// Modal styles
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};

const AdminProfile = () => {
    const { currentUser } = useAuth(); // Getting the current user from Auth context

    // State for form data (without username)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: ''
    });

    // State for form submission feedback
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // State for modal visibility
    const [openPasswordModal, setOpenPasswordModal] = useState(false);

    // State for password change fields and errors
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: ''
    });
    const [passwordError, setPasswordError] = useState('');

    // Initialize form data with the current user data
    useEffect(() => {
        if (currentUser) {
            setFormData({
                firstName: currentUser.name?.firstName || '',
                lastName: currentUser.name?.lastName || ''
            });
        }
    }, [currentUser]);

    // Handle form field changes for profile
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle password field changes
    const handlePasswordChange = (e) => {
        setPasswordData({
            ...passwordData,
            [e.target.name]: e.target.value
        });
    };

    // Handle form submission for profile update
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        // Basic validation for empty fields
        if (!formData.firstName || !formData.lastName) {
            setError('All fields are required.');
            setLoading(false);
            return;
        }

        try {
            // Example API call: await updateProfile(formData);
            setSuccess('Profile updated successfully!');
        } catch (err) {
            setError('Failed to update profile. Please try again.');
        }

        setLoading(false);
    };

    // Handle password change submission
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setPasswordError('');
        setSuccess('');

        // Validation for password fields
        if (!passwordData.currentPassword || !passwordData.newPassword) {
            setPasswordError('Both current and new password fields are required.');
            setLoading(false);
            return;
        }

        if (passwordData.newPassword.length < 6) {
            setPasswordError('New password must be at least 6 characters long.');
            setLoading(false);
            return;
        }

        try {
            // Example API call: await updatePassword(passwordData);
            setSuccess('Password updated successfully!');
        } catch (err) {
            if (err.response?.status === 400) {
                setPasswordError('Current password is incorrect.');
            } else {
                setPasswordError('Failed to update password. Please try again.');
            }
        }

        setLoading(false);
        setOpenPasswordModal(false); // Close the modal after submission
    };

    return (
        <Container sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: 12, // Adjusted for better margin
        }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 5 }}>
                Admin Profile
            </Typography>

            {/* Feedback */}
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            <Paper sx={{ p: 4 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                                error={!!error && !formData.firstName}
                                helperText={!!error && !formData.firstName && "First name is required"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                                error={!!error && !formData.lastName}
                                helperText={!!error && !formData.lastName && "Last name is required"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Update Profile'}
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                onClick={() => setOpenPasswordModal(true)} // Open the modal
                            >
                                Change Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            {/* Modal for changing password */}
            <Modal
                open={openPasswordModal}
                onClose={() => setOpenPasswordModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Change Password
                    </Typography>
                    <form onSubmit={handlePasswordSubmit}>
                        <TextField
                            fullWidth
                            label="Current Password"
                            name="currentPassword"
                            type="password"
                            value={passwordData.currentPassword}
                            onChange={handlePasswordChange}
                            required
                            error={!!passwordError && !passwordData.currentPassword}
                            helperText={!!passwordError && !passwordData.currentPassword && "Current password is required"}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="New Password"
                            name="newPassword"
                            type="password"
                            value={passwordData.newPassword}
                            onChange={handlePasswordChange}
                            required
                            error={!!passwordError && passwordData.newPassword.length < 6}
                            helperText={!!passwordError && passwordData.newPassword.length < 6 && "New password must be at least 6 characters"}
                            sx={{ mb: 2 }}
                        />
                        {passwordError && <Alert severity="error">{passwordError}</Alert>}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Update Password'}
                        </Button>
                    </form>
                </Box>
            </Modal>
        </Container>
    );
};

export default AdminProfile;
