import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper, CircularProgress, Alert } from "@mui/material";
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import { getStats } from '../../context/Stats'; // Assuming getStats() fetches the stats from context or API

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement
);

const drawerWidth = 300;

const Trends = () => {
    const [stats, setStats] = useState(null); // State for fetched stats data
    const [loading, setLoading] = useState(true); // State for loading status
    const [error, setError] = useState(null); // State for error handling

    // Fetch the data from the context or API
    useEffect(() => {
        getStats()
            .then(response => {
                setStats(response);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    // Common options for all charts
    const commonOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.raw;
                    },
                },
            },
        },
    };

    if (loading) {
        return (
            <Container sx={{ mt: 12 }}>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container sx={{ mt: 12 }}>
                <Alert severity="error">Failed to load data: {error.message}</Alert>
            </Container>
        );
    }

    // Doughnut chart for User Verification Status
    const userVerificationData = {
        labels: ['Verified', 'Pending Verification', 'Rejected', 'Blocked'],
        datasets: [{
            label: 'User Verification Status',
            data: [stats.verifiedUsers, stats.pendingVerification, stats.rejectedUsers, stats.blockedUsers],
            backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384', '#888888'],
        }],
    };

    // Line chart for Users Joined by Month
    const monthlySignupsData = {
        labels: stats.usersByMonth.map(({ year, month }) => `${month}/${year}`),
        datasets: [
            {
                label: 'Users Joined By Month',
                data: stats.usersByMonth.map(({ count }) => count),
                borderColor: '#36A2EB',
                backgroundColor: '#9BD0F5',
                fill: true,
                tension: 0.4, // Makes the line smooth
            },
        ],
    };

    // Bar chart for Total User Stats
    const userStatsBarData = {
        labels: ['Total Users', 'Active Users', 'Disabled Users', 'Free Tier Users', 'Gold Tier Users'],
        datasets: [
            {
                label: 'User Stats',
                data: [stats.totalUsers, stats.activeUsers, stats.disabledUsers, stats.freeTierUsers, stats.goldTierUsers],
                backgroundColor: ['#FF6384', '#FFCE56', '#36A2EB', '#FF6384', '#888888'],
                borderColor: ['#FF6384', '#FFCE56', '#36A2EB', '#FF6384', '#888888'],
                borderWidth: 1,
            },
        ],
    };

    // Doughnut Chart for Monthly vs Yearly Signups
    const monthlyVsYearlySignupsData = {
        labels: ['Last Month', 'This Year'],
        datasets: [{
            label: 'Signups Comparison',
            data: [stats.registeredLastMonth, stats.registeredThisYear],
            backgroundColor: ['#36A2EB', '#FFCE56'],
        }],
    };

    // Bar chart for Total User Accounts vs Khatuna
    const userAccountsVsKhatunaData = {
        labels: ['Total User Accounts', 'Total Khatuna'],
        datasets: [
            {
                label: 'Accounts vs Khatuna',
                data: [stats.totalUserAccounts, stats.totalKhatuna],
                backgroundColor: ['#36A2EB', '#FF6384'],
            },
        ],
    };

    return (
        <Container sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: 12, // Adjusted for better margin
        }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 5 }}>
                Trends
            </Typography>

            <Grid container spacing={6}>

                {/* Line Chart for Monthly User Signups */}
                <Grid item xs={12} md={12} lg={6}>
                    <Paper sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>Monthly User Signups</Typography>
                        <Line data={monthlySignupsData} options={commonOptions} height={200} />
                    </Paper>
                </Grid>

                {/* Bar Chart for Total User Stats */}
                <Grid item xs={12} md={12} lg={6}>
                    <Paper sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>Total User Stats</Typography>
                        <Bar data={userStatsBarData} options={commonOptions} height={200} />
                    </Paper>
                </Grid>

                {/* Doughnut Chart for User Verification Status */}
                <Grid item xs={12} md={12} lg={6}>
                    <Paper sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>User Verification Status</Typography>
                        <Doughnut data={userVerificationData} options={commonOptions} height={200} />
                    </Paper>
                </Grid>

                {/* Bar Chart for User Accounts vs Khatuna */}
                <Grid item xs={12} md={12} lg={6}>
                    <Paper sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>Total User Accounts vs Khatuna</Typography>
                        <Bar data={userAccountsVsKhatunaData} options={commonOptions} height={200} />
                    </Paper>
                </Grid>

                {/* Doughnut Chart for Signups Last Month vs This Year */}
                <Grid item xs={12} md={12} lg={6}>
                    <Paper sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>Signups Last Month vs This Year</Typography>
                        <Doughnut data={monthlyVsYearlySignupsData} options={commonOptions} height={200} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Trends;
