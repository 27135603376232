import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Alert, Typography, Button } from "@mui/material";
import { getPendingPhotoVerifications, verifyUser, denyUserVerification } from '../../context/User';
import ImageLoader from '../dashboard/ImageLoader ';
const drawerWidth = 300;

const VerifyAccounts = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            const result = await getPendingPhotoVerifications();
            console.log("Fetched data:", result); // Debugging log
            setData(result);
        } catch (error) {
            console.error("Error fetching data:", error); // Debugging log
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Function to format the date
    const formatDate = (createdAt) => {
        try {
            const date = new Date(createdAt);
            if (isNaN(date.getTime())) {
                throw new Error('Invalid timestamp: ' + createdAt);
            }
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' }; // Updated format
            return new Intl.DateTimeFormat('en-GB', options).format(date); // Format as dd/mm/yyyy
        } catch (error) {
            console.error('Error formatting timestamp:', error);
            return 'Invalid date';
        }
    };

    return (
        <Container sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: 20
        }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 5 }}>
                Accounts Verification
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">{error}</Alert>
            ) : data.length === 0 ? ( // Check if there are no verifications
                <Typography variant="h6" component="p" sx={{ mt: 5 }}>
                    There's nothing to verify yet!
                </Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#E95D80' }}>
                            <TableRow>
                                <TableCell sx={{ color: 'white' }}>Username</TableCell>
                                <TableCell sx={{ color: 'white' }}>Email</TableCell>
                                <TableCell sx={{ color: 'white' }}>Status</TableCell>
                                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                                <TableCell sx={{ color: 'white' }}>Image</TableCell>
                                <TableCell sx={{ color: 'white' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((verification) => (
                                <TableRow key={verification.user.username}>
                                    <TableCell>{verification.user.username}</TableCell>
                                    <TableCell>{verification.user.email}</TableCell>
                                    <TableCell>{verification.status}</TableCell>
                                    <TableCell>{formatDate(verification.createdAt)}</TableCell>
                                    <TableCell>
                                        <ImageLoader source={{ uri: verification.image }} alt="Verification" style={{ width: 100, height: 100, borderRadius: '100px' }} />
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color={"primary"} onClick={async () => {
                                            setLoading(true);
                                            await verifyUser(verification._id);
                                            setLoading(false);
                                            fetchData(); // Refetch data after verification
                                        }}>
                                            Approve
                                        </Button> 
                                        <Button style={{marginLeft: "8px", width:"100px"}} variant="contained" color={"secondary"} onClick={async () => {
                                            setLoading(true);
                                            await denyUserVerification(verification._id);
                                            setLoading(false);
                                            fetchData(); // Refetch data after verification
                                        }}>
                                            Deny
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default VerifyAccounts;
