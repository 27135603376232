import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../context/AuthContext";
import { Form, Button, Card, InputGroup } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // FontAwesome icons
import { useTranslation } from 'react-i18next';

const PHONE_IMAGE_640_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_640.png'

export default function LoginComponent() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentUser, login } = useAuth();
    const { t, i18n } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = () => {
        setLoading(true);
        login(username, password)
            .then(res => {
                console.log(res)
                if (res) {
                    navigate('/dashboard');
                } else {
                    setError('Unauthorized access'); 
                }
                setLoading(false);
            })
            .catch(err => {
                setError('Invalid username or password');
                setLoading(false);
                console.log(err);
            });
    };
    

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    function getLoginText(){
        return t('login')
    }

    return (
        <div className="container mt-5" style={{marginBottom: "80px"}}>
            <div className="d-flex justify-content-around">
                <Card style={{ width: '48rem'}}>
                    <Card.Img variant="top" className='' src={PHONE_IMAGE_640_JPG} style={{ height: "22rem", width: "40rem", margin: "auto", marginTop: "30px" }} />
                    <Card.Body>
                        <Card.Title className='text-center' style={{ color: '#e95d80', fontWeight: "bolder", fontSize: "48px" , marginTop: "20px"}}>{t('login')}</Card.Title>
                        <Card.Text>
                            <Form.Control size="lg" type="text" placeholder={t('username')} onChange={(e) => setUsername(e.target.value)} disabled={loading}/>
                            <br />
                            {/* Password Field with Toggle */}
                            <InputGroup className="mb-3">
                                <Form.Control
                                    size="lg"
                                    type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password'
                                    placeholder={t('password')}
                                    onChange={(e) => setPassword(e.target.value)}
                                    disabled={loading}
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={togglePasswordVisibility}
                                    disabled={loading}
                                    style={{border: ""}}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </Button>
                            </InputGroup>

                            {error && <p style={{ color: 'red' }}>{error}</p>}<br />
                        </Card.Text>
                        <div className="d-grid gap-2">
                            <Button variant="" style={{color: "white", backgroundColor: "#e95d80"}} size="lg" onClick={handleLogin} disabled={loading}>
                                {loading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    getLoginText()
                                )}
                            </Button></div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}
