import { React, useEffect, useState } from 'react';
import '../../index.css'

const GOLD_PHONE_IMAGE_320_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_320.png'
const GOLD_PHONE_IMAGE_640_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_640.png'
const GOLD_PHONE_IMAGE_768_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_768.png'
const GOLD_PHONE_IMAGE_1024_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1024.png'
const GOLD_PHONE_IMAGE_1280_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1280.png'
const GOLD_PHONE_IMAGE_1536_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1536.png'

const CHAPERONE = 'https://mjmarry.blob.core.windows.net/assets/gold/chaperone.jpg'
const CHATS = 'https://mjmarry.blob.core.windows.net/assets/gold/chats.jpg'
const FILTERS = 'https://mjmarry.blob.core.windows.net/assets/gold/filters.jpg'
const PROFILE = 'https://mjmarry.blob.core.windows.net/assets/gold/profile.jpg'
const VIDEOCALL = 'https://mjmarry.blob.core.windows.net/assets/gold/videocall.jpg'



const GoldMembershipSection = () => (
    <section className="position-relative text-center">
        <picture>
            <source media="(max-width: 639px)" srcSet={GOLD_PHONE_IMAGE_320_JPG} type="image/jpeg" />
            <source media="(max-width: 767px)" srcSet={GOLD_PHONE_IMAGE_640_JPG} type="image/jpeg" />
            <source media="(max-width: 1023px)" srcSet={GOLD_PHONE_IMAGE_768_JPG} type="image/jpeg" />
            <source media="(max-width: 1279px)" srcSet={GOLD_PHONE_IMAGE_1024_JPG} type="image/jpeg" />
            <source media="(max-width: 1535px)" srcSet={GOLD_PHONE_IMAGE_1280_JPG} type="image/jpeg" />
            <source media="(min-width: 1537px)" srcSet={GOLD_PHONE_IMAGE_1536_JPG} type="image/jpeg" />
            <img className="img-fluid w-100" src={GOLD_PHONE_IMAGE_1536_JPG} alt="Gold Membership" loading="eager"
                style={{ height: '100vh', objectFit: 'cover' }} />
        </picture>

        <div className='d-flex justify-content-center'>
            <div
                className="custom-style position-absolute top-50 start-0 translate-middle-y"
                style={{ marginLeft: "", maxWidth: "100%", textAlign: "center" }}
            >
                <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'center', color: "whitesmoke" }}>MUSLIMS
                </h1>
                <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'center', color: "whitesmoke" }}>JUST
                </h1>
                <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'center', color: "whitesmoke" }}>MARRY
                </h1>
                <h1 className="" style={{ fontSize: '40px', fontWeight: 'bold', textAlign: 'center', color: "#ccac00", textShadow: "4px 4px 5px black" }}>Gold <span style={{ fontSize: '40px', fontWeight: 'bold', textAlign: 'left', color: "whitesmoke", textShadow: "2px 2px 5px black" }}> Membership </span>
                </h1>
                <div className='d-flex justify-content-center'>
                    <p className="text-white" style={{ fontSize: '24px', textAlign: 'justify', width: "30%" }}>
                        <span style={{ color: "#ccac00", textShadow: "2px 2px black" }}> Gold </span> membership makes it easier to find your perfect partner with powerful filters, unlimited profiles,
                        and much more.
                    </p>
                </div>
                <div className="justify-content-center align-items-center ">
                    <a href="" target="_self" className="btn btn-danger mt-3 fw-bold"
                        style={{ borderRadius: '24px', background: "#ccac00", border: "2px dotted #ccac00", maxWidth: "80%", padding: "10px 40px 10px 40px", fontSize: "14px" }}>
                        Upgrade To Gold
                    </a>
                </div>
            </div>
        </div>
    </section>
);




const sectionsData = [
    {
        id: "search",
        title: "Your Search Priorities",
        description: "Your special someone is waiting for you on MJMarry and our search tools will help you find them. Prioritize profiles by religiosity, profession, education, language, and so much more.",
        images: {
            jpeg: [FILTERS]
        }
    },
    {
        id: "profiles",
        title: "Unlimited Profiles",
        description: "Members get unlimited profiles. We also get rid of ads so you can focus on finding your future partner.",
        images: {
            jpeg: [PROFILE]
        }
    },
    {
        id: "chat",
        title: "Free daily Instant Chat",
        description: "What are you waiting for? Message members you’re interested in instantly and start a conversation right away. You could be one Instant Chat away from getting married!",
        images: {
            jpeg: [CHATS]
        }
    },
    {
        id: "boosts",
        title: "Free Weekly Profile Boost",
        description: "Stand out from the crowd with a weekly Profile Boost. Each time you Boost, your profile goes to the front of the queue so you get seen by 30x more people.",
        images: {
            jpeg: [VIDEOCALL]
        }
    }
]

const Section = ({ title, description, images, index, width }) => (

    <section
        className="container mx-auto d-flex flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-left"
        style={{ gap: '100px', padding: '4rem 0' }}>

        {width <= "576" && <><div style={{ maxWidth: '300px' }}>
            <h2 style={{
                fontSize: '40px',
                fontWeight: 'bold',
                lineHeight: '48px',
                letterSpacing: '-1px',
                marginTop: '0px'
            }}>{title}</h2>
            <div style={{ fontSize: '18px', lineHeight: '27px', fontWeight: 'light' }}
                dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>

            <div style={{ maxWidth: '400px' }}>
                <picture>
                    <source media="(max-width: 639px)" srcSet={images.jpeg[0]} type="image/jpeg" />
                    <img className="d-block w-100" src={images.jpeg[0]} alt=""
                        loading="lazy" />
                </picture>
            </div>
        </>
        }


        {width > "576" && <>
            {index % 2 !== 0 && <div style={{ maxWidth: '400px' }}>
                <h2 style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    lineHeight: '48px',
                    letterSpacing: '-1px',
                    marginTop: '20px'
                }}>{title}</h2>
                <div style={{ fontSize: '18px', lineHeight: '27px', fontWeight: 'light' }}
                    dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>}

            <div style={{ maxWidth: '500px' }}>
                <picture>
                    <source media="(max-width: 639px)" srcSet={images.jpeg[0]} type="image/jpeg" />
                    <img className="d-block w-100" src={images.jpeg[0]} alt=""
                        loading="lazy" />
                </picture>
            </div>

            {index % 2 === 0 && <div style={{ maxWidth: '400px' }}>
                <h2 style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    lineHeight: '48px',
                    letterSpacing: '-1px',
                    marginTop: '20px'
                }}>{title}</h2>
                <div style={{ fontSize: '18px', lineHeight: '27px', fontWeight: 'light' }}
                    dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>}
        </>}
    </section>
);

export default function GoldComponent() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <GoldMembershipSection />
            <div>
                {sectionsData.map((section, index) => (
                    <Section key={section.id} {...section} index={index} width={width} />
                ))}
            </div>
        </>
    );
}
