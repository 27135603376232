import '../../css/legal.css'

export default function TCs() {
    return (
        <div className='container'>
            <div className='starting_section'>
                <h1 className='heading'>
                    Privacy Policy
                </h1>
                <h3 className='sub_heading'>
                    Last Modified: 7th March 2024
                </h3>

            </div>
            <hr></hr>
            <p>
            MJMarry values the privacy of its users ("you") and has created this Privacy Policy to demonstrate our commitment to safeguarding your personal information. This policy outlines the types of information we collect, how we use it, who we may share it with, and your options regarding its use and disclosure. We recommend that you carefully read this Privacy Policy when using our application, services, or conducting business with us. By accessing our website, application, or other online services (our "Service"), you agree to the practices described in this Privacy Policy.
                <br></br><br></br><br></br>
                If you have any questions about our privacy practices, please refer to the end of this Privacy Policy for our contact information. We also encourage you to review this Privacy Policy alongside our Terms of Service.
            </p>
            <div className='privacy terms'>
                <h1>
                    Data Gathered About You
                </h1>
                <h2>
                    1. In General
                </h2>
                <p>
                    <span  >We may collect Personal Information, including Sensitive Data and other data. Personal Information Defined Personal Information means any information at all that enables us to identify the actual living person and communicate with that person directly. 3 Optional Information Comments or Content – Photographs, video, profile and lifestyle information, comments related to your origin, religious beliefs 7 religious beliefs and/or sexual orientation. By sharing Sensitive Data with us, you grant us the right to collect, use and disclosure Sensitive Data as per applicable privacy laws in the US. We may also collect your geolocation information if you agree. We can collect this data through a website, mobile app, or other online services. By using the Service, you consent to MJMarry to collect, process and store information about the delivery of the Service. When providing the personal information through our Service, the information can be directed to servers located in the US and in other countries worldwide where data protection laws may differ, but all are in compliance with the US laws.</span>
                </p>
                 
                <h2>
                    2. Information you provide
                </h2>
                <p>
                    <span  >To register as a user on MJMarry, you'll need to sign in using either your Facebook login or email address. If you sign in via Facebook, you authorize us to access certain information from your Facebook account, which may include your public Facebook profile (as per your privacy settings on Facebook), email address, interests, likes, gender, birthday, education history, relationship preferences, current city, photos, personal description, friend list, and details or photos of your Facebook friends who may be mutual Facebook friends Furthermore, you will be required to permit MJMarry to access your location information from your device when you download or use the Service. Additionally, we may collect and store any personal information you provide while using our service or through other means. This may include personal details like your name, address, email address, and phone number, as well as financial information if you engage in transactions with us. You may also provide photos, personal descriptions, and information related to your preferences, such as search criteria (e.g., distance, age range, gender) for our recommendations. If you engage in chats with other MJMarry users, we collect the content of those conversations, and if you contact us for customer support or other inquiries, we also collect the details of those interactions.</span>
                </p>
                 
                <p>
                    <span  >Types of Data – Fair Collection of Personal Data:</span>
                </p>
                 
                <p>
                    <span  >To access our services, you are required to complete a registration form, which asks for personal information.</span>
                </p>
                 
                <p>
                    <span  >You can also supply The Company with a mobile phone number to receive sms alerts as part of our profile verification process. Your email address and phone number will remain private and will not be visible to the public. We will not share your mobile information with third parties or affiliates for marketing or promotional purposes. Additionally, we do not share text messaging opt-in data or consent details with any third parties.</span>
                </p>
                <p>
                    <span  >Location Data (geolocation): MJMarry’s main purpose and mission is to allow users to locate people around them, so that they can meet online or offline. It will ask your permission to collect and manage your geolocation to let MJMarry to identify other users that agreed to geolocation.</span>
                </p>
            
                <p>
                    <span  >Note that both the Apple App Store and Google Play Store have chosen not to pass certain data, like banking and financial information, to mobile applications.</span>
                </p>
                 
                <p>
                    <span  >Mobile Data: When you employ the application MJMarry, it gathers logging information from server logs that may encompass details such as IP addresses, operating systems, or any application that crashes.</span>
                </p>
                 
                <p>
                    <span  >Selfie for Liveness Verification: To prevent fraudulent sign-ups and fake accounts, we estimate your liveness by analyzing your facial image. As part of the registration process, we ask for a selfie. This selfie is shared only with our partner Yoti (Yoti USA Inc. for US users), who performs the liveness check. Yoti cannot link your selfie to any other personal data, and no other data is shared with them. Yoti processes this data on its US-based servers to determine liveness, then returns the result to us. Once the check is complete, Yoti deletes your selfie and does not use it for any other purpose.</span>
                </p>
                 
                <h2>
                    3. Utilization of Technologies for Information Collection
                </h2>
                <p>
                    <span  >We use a variety of technologies for collecting information from your device and regarding your activities on our Service.</span>
                </p>
                 
                <h3>
                    Information collected automatically
                </h3>
                 
                <p>
                    <span  >We automatically obtain data from your browser or device when you visit our Service. This could be your IP address, device ID and type, your browser type and language, the operating system used by your device, access times, your geographic location according to your mobile device while our application is active, and the address of the website that brought you to us.</span>
                </p>
                 
                <h3>
                    Cookies and Similar data
                </h3>
                <p>
                    <span  >When you come to visit our Service we can install one or more cookies or other technologies on your device in order to allow easier access to our Service and to personalize your experience. We also may use a cookie to automatically collect information about your activity on our Service, such as the pages you visit, the time and date of your visits and the links you click. If we advertise, we (or third parties) may utilize the information collected on our Service to present MJMarry advertisements to you in other sites or applications.</span>
                </p>
                 
                <h3>
                    Pixel tags
                </h3>
                <p>
                    <span  >We also place pixel tags (known as web beacons or clear GIFs) on Web pages, ads, and emails. These small, invisible pictures are used to obtain access to cookies and monitor user activities ( e.g. how often a page has been viewed). We employ the technique of using pixel tags to gauge the popularity of various features and services that we offer. Pixel tags are not only utilized by ad companies to measure how many ads are shown, but also how they perform, like how many people clicked on an ad.</span>
                </p>
                 
                <h3>
                    Mobile device IDs
                </h3>
                 
                <p>
                    <span  >If you are using the MJMarry app, we apply mobile device IDs, or Advertising IDs for iOS 6 and later, rather than cookies, to know you. We do it to save your choices and monitor how you use the app. Unlike cookies, device IDs cannot be cleaned, but Advertising IDs are able to be reset in “Settings” on your iPhone. Furthermore, ad companies use the device IDs or Advertising IDs to track your use of the app, count the number of ads it shows, measure the success of ads and show ads that are more relevant to you. Companies that analyze device IDs track information about app usage. All these practices are legal in the US.</span>
                </p>
                 
                <h2>
                    4. Information collected by third-parties for advertising purposes
                </h2>
                 
                <p>
                    <span  >We may grant permissions to service providers, advertising companies and ad networks, and other third parties to place ads on our Service and elsewhere. These companies could employ tracking technologies, such as cookies or web beacons, to gather information about the users who visit or interact with their ads. We do not give out any unmasked or uncovered personal data to third parties.</span>
                </p>
                 
                <h2>
                    5. Utilization of Collected Information
                </h2>
                <p>
                    <span  >In General. We may use the information we collect about you to:</span>
                </p>
                 
                <p>
                    <span  >- Allow us to deliver and improve our products and services, and operate our organisation.</span>
                </p>
                 
                <p>
                    <span  >- Assist with managing your account and offering customer support.</span>
                </p>
                 
                <p>
                    <span  >- Carry out research and analytical work on your use or intentions to use our or other organizations’ products, services and content.</span>
                </p>
                 
                <p>
                    <span  >- To communicate with you via e-mail, postal mail, telephone, mobile and other devices related to products and services of interest to you offered by us or by other third parties.</span>
                </p>
                 
                <p>
                    <span  >- Allow you to create, view, and interact with content and ads tailored to your preferences on our platform and other websites, as long as our ads are displayed while you're browsing elsewhere.</span>
                </p>
                 
                <p>
                    <span  >- Website or mobile application analytics;</span>
                </p>
                 
                <p>
                    <span  >- Process your participation in contests and sweepstakes, and verify your eligibility and potential winnings.</span>
                </p>
                 
                <p>
                    <span  >- Enforce or exercise any rights outlined in our terms and conditions.</span>
                </p>
                 
                <p>
                    <span  >- As previously explained to you at the time of collection, the services or functions will be performed.</span>
                </p>
                 
                <p>
                    <span  >- Pass on to other organizations which in our entire view provide items that would attract your attention.</span>
                </p>
                 
                <h2>
                    6. Job applicants
                </h2>
                <p>
                    <span  >In the case your Personal Information is provided while applying for a position with the company MJMarry, the information will be used only for the purposes of considering and acting on your application. We will only hold onto your Personal Information to use it to evaluate your eligibility for current or potential positions in the US. The following data, such as your name, address, previous education and experience, and additional information you provide, may be disclosed to third parties – service providers retained by us to collect, maintain and analyze candidate submissions for job postings in the US.</span>
                </p>
                 
                <p>
                    <span  >We may undertake those functions directly, or indirectly by engaging a third party vendor to perform those functions for us provided that such agent will be obligated to use your personal information only to provide services for us in the U.S. Additionally, if you log in to our Service using a third party social platform, such as Facebook, we may share non-personal information with the platform to the extent permitted by your agreement with it and its privacy settings in the US.</span>
                </p>
                 
                <h2>
                    7. Sharing Your Information with Other Users
                </h2>
                 
                <p>
                    <span  >When you become a MJMarry user, you will be perceptible to the rest of the MJMarry users. Other users (and in the case of any sharing features available on MJMarry, the people or software that a MJMarry user may choose to share you with) will be able to see information you have given us directly or through Facebook, such as your pictures, any additional pictures you upload, your first name, age, distance, description, and information you share with this view If you swipe right or vote “yes” for a person, you will have the opportunity to chat with that person if she or he swipes right or votes “yes” for you, so if you chat with your match, of course your match will see your chat.</span>
                </p>
                 
                <h2>
                    8. Personal information
                </h2>
                <p>
                    <span  >We do not disclose your personal information to third parties, unless it is otherwise stated in this Privacy Policy or unless we first notify you and offer you the opportunity to choose not to share your personal information. We may share personal information with:</span>
                </p>
                 
                <h2>
                    9. Service providers
                </h2>
                <p>
                    <span  >However, we may disclose such information, which may include personal and financial information, to third parties that provide services to MJMarry. Such services may include order fulfillment, customer service and marketing assistance, business and sales analysis, ad tracking and analytics, member screenings, support our Service functionality, and support contests, sweepstakes, surveys, and other features offered through our Service in the US. Service providers in the US may have access to personal information required for them to carry out necessary duties but they are not allowed to disclose or use such information for other purposes in the US.</span>
                </p>
                 
                <h2>
                    10. Other Businesses Under the MJMarry Group
                </h2>
                <p>
                    <span  >We may also disclose to other MJMarry group companies the information we collect, including your profile and personal information such as your name, contact details, photos, preferences, interests, activities and transactions on the Service.</span>
                </p>
                 
                <h3>
                    Other Situations
                </h3>
                 
                <p>
                    <span  >We may disclose your information, including personal information:</span>
                </p>
                 
                <p>
                    <span  >- In a subpoena or any similar investigative demand, a court order, or a request for cooperation from a law enforcement or any other government agency in the US; to establish or defend its legal rights; protect against any liabilities; or pursuant to any applicable law in the US. In such cases, we may withdraw any legal action or claim.</span>
                </p>
                 
                <p>
                    <span  >- When we believe disclosure is required to facilitate lawful investigations, prevention or remediation of violations of law, fraud, corruption or other wrongful conduct in the US; to safeguard the rights, property or safety of our company, our users, our employees or others in the US; to comply with legal requirements in the US or to cooperate with law enforcement; or to</span>
                </p>
                 
                <p>
                    <span  >- In the event of a major corporate action in the United States including the selling of our business, divestiture, merger, consolidation, or asset sale, or in the unlikely chance of bankruptcy.</span>
                </p>
                 
                <p>
                    <span  >- For our employees, consultants, contractors, subcontractors, professional advisors, insurers or agents located in the US should we believe it will help MJMarry or any of its group companies.</span>
                </p>
                 
                <h2>
                    11. Aggregated and/or Non-personal Data
                </h2>
                <p>
                    <span  >We may also share non-personal information we collect under any of these situations. We may also disclose it to third parties in order to create, list, and display advertisements on our Service and on the websites or mobile applications of other companies, and for analysis and reporting on advertising you see. We can combine additional non-personal information collected from other sources with the non-personal information that we collect. Similarly, we may disclose aggregated non-personally identifiable information, or personal information that has been de-identified, de-identified, or hashed, with third parties such as advisors, advertisers, and investors in order to do general business analysis, advertisements, marketing, or other business tasks. For instance, we could use a data provider allowing him or her to collect web log data from you (including IP address and information about your browser or operating system), or we may place or recognize a unique cookie on your browser in order to enable you to receive customized ads or content. The cookie may contain hashed de-identified demographic or other data linked to data you have submitted to us on a voluntary basis, which we may provide to a data provider in non-human readable form. We may also disclose geolocation information in de-identified form to MJMarry companies and third parties for the aforementioned purposes. To decline access to your geolocation data, all you have to do is stop using the MJMarry app.</span>
                </p>
                 
                <p>
                    <span  >DNT or Do Not Track disclosure is a privacy preference set by users in web browsers. DNT provides users a week to prevent websites and services to collect some information about their webpage visits over a period of time and across websites or online services. That is why to provide meaningful options pertaining to information gathering, we provide you with opt-out links above. Yet, we do not recognize or respond to any DNT signals, since the Internet industry tries to define what DNT is, what it means to comply with DNT, and a standard approach to responding to DNT.</span>
                </p>
                 
                <h2>
                    12. Third-party websites
                </h2>
                <p>
                    <span  >On our Service there are several links you can click to get access to other websites that do not fall under this Privacy Policy. For instance, if you click on an ad on our Service you may be transferred to a website not under our control. While these third-party sites may collect information, including personal information, from you independently and sometimes share information about your activities on those sites with us. We advise you to read privacy policies on all third-party sites you visit by clicking on the “privacy” link usually positioned at the foot of the page you are visiting.</span>
                </p>
                 
                <p>
                    <span  >If you are a user of an MJMarry account you can go to your account through the Service and click on Settings where you can review and change your personal information. The applicable privacy legislation may give you the right to access and/or request correction of inaccuracies or omissions in your personal information that is in our custody or control. The process of access request will be facilitated by the Privacy Officer.</span>
                </p>
                 
                <p>
                    <span  >We will reply to all requests in the time period allowed by all privacy laws, and will do so as completely and accurately as we can. Any amendment to the personal information will be communicated to any recipient to which it was disclosed in a timely manner.</span>
                </p>
                 
                <p>
                    <span  >In some rare cases, we may not be able to grant access to information specific personal information that we keep. In terms of security measures, not all personal information can be viewed and edited by the Privacy Officer. If access or correction cannot be provided we will inform the requesting individual in writing within 30 days of the reasons for the refusal.</span>
                </p>
                 
                <h2>
                    13. Data retention
                </h2>
                <p>
                    <span  >If you decided to delete or disable your account, we are going to save your data for 3 years for analytical reasons and archive for integrity of recordkeeping, to prevent fraud, collect owed fees, enforce terms and conditions, take actions that we consider necessary to protect integrity of our Services or our users, or any other action that set by local law in the US. Following this period, personally identifiable data associated with the account such as email address, phone number and photos will become anonymous/masked. To maintain the sanctity of the platform, anonymised member data will remain within the US. Besides, if some information already has been shared with third parties, as stated above, the retention of such information will be governed by the policies of those third parties in the US.</span>
                </p>
                 
                <h2>
                    14. Data deletion
                </h2>
                <p>
                    <span  >We document the time at which you ask for the deletion of your account. You can use your old email or phone number to sign up for a new account.</span>
                </p>
                 
                <p>
                    <span  >As soon as you send a request for deletion, your account, profile, and photos are made inaccessible and will no longer be visible to other MJMarry members.</span>
                </p>
                 
                <p>
                    <span  >We fail to send any more emails to you beyond this confirmation of this request.</span>
                </p>
                 
                <p>
                    <span  >After up to three years, we will then delete your personal identifiable information for good (example photos/email address/phone number/profile).</span>
                </p>
                 
                <h2>
                    15. Explanation for Data Deletion
                </h2>
                <p>
                    <span  >It is possible that law enforcement will request data pertaining to conversations that took place over a former member’s account several months after they have stopped using our service. This can be due to the start of a fresh or continuing US investigation. However, the simple deletion of everything upon request of a user is not satisfactory because it might deny such evidence.</span>
                </p>
                 
                <p>
                    <span  >Block history is preserved during this period to avoid harassment of other members by the same users after being blocked. This is to safeguard all members. This would immediately permit a blocked user to rematch and message/harass a member once again in the US.</span>
                </p>
                 
                <p>
                    <span  >This time frame is employed to ensure that the phone number/email address/login credentials are retained such that one user does not create duplicate accounts thus harassing other members in the US.</span>
                </p>
                 
                <h2>
                    16. Multiple or Repetitive Accounts
                </h2>
                <p>
                    <span  >As stated in our Terms of Service, we do not permit multiple accounts. Retaining your email address and phone number helps us prevent misuse of our services in this manner.</span>
                </p>
                 
                <h2>
                    17. Consent and Acknowledgment of Privacy Policy
                </h2>
                <p>
                    <span  >It should be noted that the above policy is specified in our Privacy Policy and Terms of Service. The exact time, when each member agrees to the abovementioned policies, is registered.</span>
                </p>
                 
                <h2>
                    18. Your Choices Regarding Information Collection and Use
                </h2>
                <p>
                    <span  >You may opt-out of supplying us with certain information, however, such an action could cause you to not be able to use some features of the Service because such information may be necessary to allow a user to register, purchase products or services, enter a contest, promotion, survey, or sweepstakes, ask a question, or initiate any other transaction.</span>
                </p>
                 
                <p>
                    <span  >Alternatively, our Service may also notify your phone or mobile device. These alerts can be turned off by going into the “App Settings” section of the app or by configuring the settings on your mobile device.</span>
                </p>
                 
                <p>
                    <span  >You may also manage the information received from cookies. Through your browser settings you can delete or block cookies. For guidance, click on 'help' in the toolbar of most browsers.</span>
                </p>
                 
                <h2>
                    19. Safeguarding Personal Information
                </h2>
                <p>
                    <span  >We implement security controls to ensure that your personal data is protected from unauthorized access or disclosure. But security cannot be achieved in any system. Thus, while we do our best to maintain the security of your information, we cannot provide a guarantee that your personal information, chats, or other communications will be completely secure. Additionally, it is essential that users act cautiously with their personal information and never send their personal information using unsecure emails.</span>
                </p>
                 
                <p>
                    <span  >You imply that MJMarry can inform you in electronic form about security, privacy, and administrative issues such as security breaches. If a security breach occurs, MJMarry may post a notice on our Service. MJMarry may also email you at the provided email address.</span>
                </p>
                 
                <h2>
                    20. Data You Share About Yourself When Using Our Service
                </h2>
                <p>
                    <span  >We offer spaces on our Service where you can post information regarding yourself and others and communicate with others. All such postings are subject to our Terms of Service. Secondly, when you share your personal information voluntarily on viewing public pages, that data will be visible to everyone and other people in the US can collect it and use it. For instance, if you write down your e-mail address, you may get unsolicited messages. Since it is impossible to predict who will view your posting or what other users might do with the voluntarily provided information, we recommend taking care of your personal information. However, MJMarry may not be made liable for data published on its site and released by a Member.</span>
                </p>
                 
                <h2>
                    21. Children's privacy
                </h2>
                <p>
                    <span  >While our service is a general audience service, we limit the use of our Service to those who are 18 or older. We do not intentionally collect, retain, or use information on children under 18 years without informed parental approval.</span>
                </p>
                 
                <h2>
                    22. Transfer of Data and Agreement for International Data Processing
                </h2>
                 
                <p>
                    <span  >We are a international corporation that has users and functions in various countries from the US. We have implemented data practices aimed at protecting information but we may not always know where such personal information can be accessed or processed. Although the main location of our data centres in the US, we can transfer personal and other information to other offices, which are located outside the US. Besides that, we can also engage other companies and people to perform functions on our behalf. If we disclose personal information to a third party or to our employees outside the US, we will ensure they provide reasonable assurances that any information we may provide to them is suitably safeguarded and in compliance with this Privacy Policy and the requirements of applicable privacy laws and we will obtain confirmation of such safeguards.</span>
                </p>
                 
                <p>
                    <span  >If you are visiting from the US or other jurisdictions which are regulated by laws governing data collection and use, please note that you agree to the transfer of your personal information, which includes sensitive personal information, from your region to countries that may not have data protection laws equivalent to the ones that apply in the US, including the US. Through disclosure of your personal information you agree to any transfer and processing as set out in this Policy.</span>
                </p>
                 
                <h2>
                    23. Absence of Third-Party Entitlements
                </h2>
                <p>
                    <span  >The inability to disclose any personal information regarding the users of the website makes this Privacy Policy not create the rights enforceable by third parties.</span>
                </p>
                 
                <h2>
                    24. Rewording of (Modifications to this Privacy Policy)
                </h2>
                <p>
                    <span  >We will make occasional changes to this Privacy Policy as applicable laws change, as our data collection and use practices evolve, as the features of our Service change, or as technology improves. Whenever we make changes to that Privacy Policy, we will change the “last update” date at the top of that Privacy Policy which is posted on the Services under “Settings” also on MJMarry.com, and you should review regularly for the latest version, which is the version which governs. When we make any material changes to this Privacy Policy, we will notify you via reasonable means, either through the Services or via e-mail. Please review the changes carefully. By continuing to use the Services after any changes are posted, you accept the Terms of Service, this Privacy Policy, and any changes and agreements. If you do not agree to such changes, you can terminate your account on the instructions under Settings.</span>
                </p>
            </div>
        </div>
    )
}