import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Button, NavDropdown } from "react-bootstrap";
import logo from "../pictures/logo.svg";
import '../../index.css'
import { useTranslation } from 'react-i18next';
import {useAuth} from "../context/AuthContext";

export default function NavBar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const navigate = useNavigate();
    const { currentUser, logout } = useAuth();
    const [activeLink, setActiveLink] = useState("");
    const { t, i18n } = useTranslation();

    const handleLinkClick = (linkName) => {
        setActiveLink(linkName);
        setIsNavExpanded(false);
    };

    const currentLanguage = i18n.language.toUpperCase();

    function changeLanguage(language) {
        i18n.changeLanguage(language);
        localStorage.setItem("i18nLanguage", language);
    }


    console.log(isNavExpanded)

    function handleLogout() {
        logout().then((res) => {
            console.log(res)
            navigate("/login")
        }).catch(err => {
            console.log(err)
        })
    }

    function handleLogin() {
        navigate("/login")
    }

    function getLoginText(){
        return currentUser?t('logout'):t('login')
    }

    return (
        <Navbar style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }} expand="lg" bg="white" expanded={isNavExpanded}
            activeKey={window.location.pathname}
        >
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        src={logo}
                        width="60"
                        height="60"
                        className="d-inline-block align-top"
                        alt="Logo"
                        style={{ marginLeft: '', marginRight: '' }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setIsNavExpanded(!isNavExpanded)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto links" style={{ textAlign: "center" }} activeKey={window.location.pathname}>
                        <Nav.Link
                            href="/about"
                            onClick={() => handleLinkClick("about")}
                            style={{ marginLeft: '1rem', marginRight: '1rem', color: "black" }}
                            className={`nav-link  ${!isNavExpanded == true ? "" : "mx-auto custom_btn"} ${activeLink === "/about" ? "active" : ""}`}
                        >
                            {t('about')}
                        </Nav.Link>
                        <Nav.Link
                            href="/help"
                            onClick={() => handleLinkClick("help")}
                            style={{ marginLeft: '1rem', marginRight: '1rem', color: "black" }} // Added inline style for bold text
                            className={`nav-link ${!isNavExpanded == true ? "" : "mx-auto custom_btn"} ${activeLink === "help" ? "active" : ""}`}
                        >
                            {t('help')}
                        </Nav.Link>
                        <Nav.Link
                            href="/privacy"
                            onClick={() => handleLinkClick("privacy")}
                            style={{ marginLeft: '1rem', marginRight: '1rem', color: "black" }} // Added inline style for bold text
                            className={`nav-link ${!isNavExpanded == true ? "" : "mx-auto custom_btn"} ${activeLink === "help" ? "active" : ""}`}
                        >
                            {t('privacy')}
                        </Nav.Link>
                        <Nav.Link
                            href="/safety"
                            onClick={() => handleLinkClick("safety")}
                            style={{ marginLeft: '1rem', marginRight: '1rem', color: "black" }} // Added inline style for bold text
                            className={`nav-link ${!isNavExpanded == true ? "" : "mx-auto custom_btn"} ${activeLink === "help" ? "active" : ""}`}
                        >
                            {t('safety')}
                        </Nav.Link>
                        {/* 
                        <Nav.Link
                            href="/gold"
                            onClick={() => handleLinkClick("gold")}
                            style={{ marginLeft: '1rem', marginRight: '1rem', color: "#ccac00" }} // Added inline style for bold text
                            className={`nav-link gold ${!isNavExpanded == true ? "" : "mx-auto custom_btn"} ${activeLink === "gold" ? "active" : ""}`}
                        >
                            {t('gold')}
                        </Nav.Link>*/}
                    </Nav>
                    {currentUser && <Nav.Link
                        href="/dashboard"
                        onClick={() => handleLinkClick("help")}
                        style={{ marginLeft: '1rem', marginRight: '1rem', color: "black" }} // Added inline style for bold text
                        className={`nav-link ${!isNavExpanded == true ? "" : "mx-auto custom_btn"} ${activeLink === "help" ? "active" : ""}`}
                    >
                        {t('dashboard')}
                    </Nav.Link>}
                    <Nav>
                        <NavDropdown title={t(currentLanguage)} id="basic-nav-dropdown" style={{ fontSize: "15px", textAlign: "center", paddingRight: "5px"}}>
                            <NavDropdown.Item onClick={() => changeLanguage('en')}>{t('english')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage('ar')}>{t('arabic')}</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Button
                            variant="custom-btn"
                            size="md"
                            active
                            onClick={() => {
                                if (currentUser) {
                                    handleLogout()
                                }else{
                                    handleLogin()
                                }
                            }}
                            className={`mx-auto ${!isNavExpanded == true ? "" : "mx-auto custom_btn"}`}
                            style={{
                                marginLeft: '', marginRight: '', fontWeight: "bold", background: "#e95d80",
                                color: "white",
                                border: "2px solid #e95d80",
                                borderRadius: "3px",
                                padding: "5px 10px",
                            }}
                        >
                            {getLoginText()}
                        
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
