export const FAQs = [
    {
        question: "How do I set up my profile on MJMarry?",
        response: "Setting up your profile is easy. Simply click on the 'Sign Up' button and follow the steps to complete your profile setup."
    },
    {
        question: "Is my personal data kept safe?",
        response: "Yes, we take your privacy and security seriously. Your personal information remains confidential and will never be shared with third parties without your permission."
    },
    {
        question: "How do the matches work on MJMarry?",
        response: "Our matching system suggests potential partners based on your profile preferences, taking into account your interests, values, and partner criteria."
    },
    {
        question: "Can I message my matches directly?",
        response: "Absolutely! Once you’ve matched with someone, you can start chatting with them directly through our secure messaging platform."
    },
    {
        question: "What should I do if I encounter any issues?",
        response: "If you run into any problems or have concerns, please reach out to our support team, and we’ll be happy to help."
    },
    {
        question: "Is MJMarry free to use?",
        response: "Yes, MJMarry is completely free to use, with no hidden fees or charges."
    },
    {
        question: "How can I include a chaperone (Wali) in my conversations?",
        response: "You can enable the chaperone (Wali) feature in your settings to ensure an added layer of trust and comfort during your conversations."
    },
    {
        question: "Is MJMarry available globally?",
        response: "Currently, MJMarry is only available to users in Pakistan. However, we plan to expand worldwide in the near future."
    },
    {
        question: "Can I filter matches based on religious or cultural preferences?",
        response: "Yes! You can customize your search by religious, cultural, and other preferences to find the most compatible matches."
    }
];
