import React, {useState} from 'react';
import {Button, Card, Modal, Form, Alert} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';
import {deleteAccount, disableAccount, sendOTPVerification} from "./../../context/User"
import './style.css'
import {useAuth} from "../../context/AuthContext";

export default function DisableAccountComponent() {
    const navigate = useNavigate();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otpArray, setOtpArray] = useState(Array(6).fill(''));
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [actionType, setActionType] = useState(''); // 'temporary' or 'permanent'
    const [tempBtnHover, setTempBtnHover] = useState(false)
    const {currentUser} = useAuth()

    console.log(currentUser)

    const sendOtpToEmail = () => {
        setLoading(true);
        const requestType = actionType === 'temporary'?"disable":"delete"
        sendOTPVerification(requestType).then(res => {
            console.log("OTP sent to user's email");
            setOtpSent(true);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false);
        })
    };

    const handleOtpChange = (index, value) => {
        if (isNaN(value)) return; // Only allow numbers
        const newOtpArray = [...otpArray];
        newOtpArray[index] = value;
        setOtpArray(newOtpArray);

        // Focus next input automatically
        if (value !== '' && index < otpArray.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleOtpKeyDown = (index, e) => {
        if (e.key === 'Backspace' && otpArray[index] === '' && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    const verifyOtp = () => {
        setLoading(true);
        const otp = otpArray.join('');
        const successMessageText =
            actionType === 'temporary'
                ? 'Your account is temporarily disabled. You can reactivate it anytime by logging back in.'
                : 'Your account is now disabled and in the process of permanent deletion.';

        if (actionType === 'temporary') {
            disableAccount(otp).then(res => {
                setSuccessMessage(successMessageText);
                setShowConfirmModal(false);
                setOtpSent(false);
                setOtpArray(Array(6).fill(''));
            }).catch(err => {
                setError('Invalid OTP. Please try again.');
            }).finally(() => {
                setLoading(false);
            })
        } else {
            deleteAccount(otp).then(res => {
                setSuccessMessage(successMessageText);
                setShowConfirmModal(false);
                setOtpSent(false);
                setOtpArray(Array(6).fill(''));
            }).catch(err => {
                setError('Invalid OTP. Please try again.');
            }).finally(() => {
                setLoading(false);
            })
        }
    };

    const handleCancel = () => {
        setShowConfirmModal(false);
        setOtpSent(false);
        setOtpArray(Array(6).fill(''));
        setError('');
        setActionType('');
    };

    return (
        <div className="mt-5" style={{
            marginBottom: "80px",
            background: "linear-gradient(150deg, #f2f2f2, #f2f2f2)",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <div className="d-flex justify-content-center align-items-center">
                <Card className="account-card">
                    <Card.Body>
                        {!successMessage ? (
                            <>
                                <Card.Title className="account-title">
                                    Account Management
                                </Card.Title>
                                <Card.Text className="account-text">
                                    Manage your account settings carefully. If you choose to delete your account, all of
                                    your data will be disabled for 30 days and will be permanently deleted after that
                                    period.
                                </Card.Text>
                                <div className="d-flex gap-3">
                                    <Button
                                        variant="warning"
                                        size="lg"
                                        className="custom-button"
                                        onClick={() => {
                                            setActionType('temporary');
                                            setShowConfirmModal(true);
                                        }}
                                        onMouseOver={() => {
                                            setTempBtnHover(true)
                                        }}
                                        onMouseLeave={() => {
                                            setTempBtnHover(false)
                                        }}
                                        style={{
                                            color: "black",
                                            backgroundColor: tempBtnHover ? "#f2f2f2" : "#f2f2f2"
                                        }}
                                    >
                                        Temporarily Disable Account
                                    </Button>
                                    <Button
                                        variant="danger"
                                        size="lg"
                                        className="custom-button"

                                        onClick={() => {
                                            setActionType('permanent');
                                            setShowConfirmModal(true);
                                        }}
                                        style={{
                                            color: "black",
                                            backgroundColor: "#f2f2f2"
                                        }}
                                    >
                                        Permanently Delete Account
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Alert variant={actionType === 'temporary' ? 'warning' : 'success'}>
                                {successMessage}
                            </Alert>
                        )}
                    </Card.Body>
                </Card>
            </div>

            {/* Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={handleCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{color: actionType === 'temporary' ? '#e95d80' : '#e95d80'}}>
                        {otpSent ? 'Enter OTP' : actionType === 'temporary' ? 'Confirm Temporary Disable' : 'Confirm Permanent Deletion'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!otpSent ? (
                        <div className="modal-text">
                            {actionType === 'temporary'
                                ? 'Are you sure you want to temporarily disable your account? You can reactivate it anytime by logging back in.'
                                : 'Are you sure you want to permanently delete your account? This action cannot be undone.'}
                        </div>
                    ) : (
                        <>
                            <Form.Group>
                                <Form.Label>Enter the 6-digit OTP sent to your email:</Form.Label>
                                <div className="d-flex justify-content-center">
                                    {otpArray.map((digit, index) => (
                                        <Form.Control
                                            key={index}
                                            id={`otp-input-${index}`}
                                            type="text"
                                            value={digit}
                                            maxLength="1"
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                            onKeyDown={(e) => handleOtpKeyDown(index, e)}
                                            className="otp-input"
                                            disabled={loading}
                                        />
                                    ))}
                                </div>
                            </Form.Group>
                            {error && <p style={{color: 'red', marginTop: '10px'}}>{error}</p>}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel} disabled={loading} className="custom-button">
                        Cancel
                    </Button>
                    {!otpSent ? (
                        <Button
                            variant={actionType === 'temporary' ? 'warning' : 'danger'}
                            onClick={sendOtpToEmail}
                            disabled={loading}
                            className="custom-button"
                            style={{
                                color: "white",
                                fontWeight: "bold",
                                backgroundColor: actionType === 'temporary' ? '#e95d80' : 'red',
                                border: "none",
                            }}
                        >
                            {loading ? 'Sending OTP...' : 'Yes, Proceed'}
                        </Button>
                    ) : (
                        <Button
                            variant={actionType === 'temporary' ? 'warning' : 'danger'}
                            onClick={verifyOtp}
                            disabled={loading}
                            className="custom-button"
                            style={{
                                color: "white",
                                fontWeight: "bold",
                                backgroundColor: actionType === 'temporary' ? '#e95d80' : 'red',
                                border: "none",
                            }}
                        >
                            {loading ? 'Verifying...' : 'Submit OTP'}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
}
